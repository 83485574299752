<template>
  <section id="cluster-centers">
    <div class="vx-row">
      <vs-col vs-w="12">
        <h2 class="mb-0">
          {{ lang.clustering.centersTable.title[languageSelected] }}
        </h2>
      </vs-col>
    </div>

    <div class="vx-row">
      <vs-col vs-w="12">
        <div class="pt-4 pb-base vs-con-loading__container">
          <CentersTable
            :cluster-centers="clusterCenters"
            v-if="clusterCenters"
          />
        </div>
      </vs-col>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClusterCenters',
  data() {
    return {
      clusterId: null,
      clusterCenters: null
    }
  },
  components: {
    CentersTable: () => import('./CentersTable.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('clusters', ['centers', 'clusters'])
  },
  methods: {
    ...mapActions('clusters', ['getCenters', 'getClusters']),
    async initCenters() {
      this.$vs.loading()
      try {
        this.clusterId = this.$route.params.clusterId

        await this.getCenters(this.clusterId)
        this.clusterCenters = this.centers(this.clusterId).centers
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.lang.clustering.centersTable.messages.getCenters.error
            .title[this.languageSelected],
          text: this.lang.clustering.centersTable.messages.getCenters.error
            .text[this.languageSelected]
        })
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  async mounted() {
    if (!this.clusters.length) {
      await this.getClusters()
    }
    this.initCenters()
  }
}
</script>
